<!-- 文件描述: 任务结果 -->
<!-- 创建时间: 2022/09/14；创建人: 阿苏 -->
<!-- 最后修改时间: 2022/09/14；最后修改人: 阿苏-->
<template>
  <div class="task-result-page">
    <div class="active">
      <div class="active-info">
        <div class="item-info">
          <p class="lable">任务模版：</p>
          <p class="template-name">{{moduleName}}</p>
        </div>
        <!-- <div class="item-info">
          <p class="lable">任务名称：</p>
          <p class="value">{{taskName}}</p>
        </div> -->
        <div class="item-info">
          <p class="lable">时间：</p>
          <p class="value">{{endTime}}</p>
        </div>
      </div>
      <el-button type="primary" class="add-taskp-btn" @click="downloadData">下载结果</el-button>
    </div>
    <div>
      <el-tabs v-model="activeName" @tab-click="handleClick" :page="false">
        <el-tab-pane v-for="(item,index) in exampleData" :key="index" :label="item" :name="item">
          <TableModule :tableData="tableData" :page="true" :total="total" @pageChange="pageChange">
            <el-table-column v-for="(res,i) in tableHead" :key="i" :prop="res" :label="res" align="center">
            </el-table-column>
          </TableModule>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>

</template>

<script>
import TableModule from "../../components/Table/TableModule.vue";
import { getResultTableName, downloadData, getResultTableData } from "../../http/api/task"
import axios from 'axios'
export default {
  name: 'AcquisitionsystemTaskResult',
  components: {
    TableModule
  },
  data () {
    return {
      activeName: "",
      moduleName: "",
      endTime: "",
      tableData: [],
      tableHead: [],
      exampleData: [],
      taskName: "",
      total: 0,
      pages: 1,
    };
  },
  watch: {
    $route: {
      handler (val) {
        console.log(val);
        let { taskName, endTime } = val.query
        this.moduleName = taskName
        this.taskName = taskName
        this.endTime = endTime
        this.getResultTableName()
      },
      immediate: true
    }
  },
  mounted () {
  },
  methods: {
    /**
 * @description 标签页选择
 * @param 
 */
    async handleClick () {
      try {
        let { code, body } = await getResultTableData({
          taskName: this.taskName,
          tableName: this.activeName,
          pages: this.pages,
          rows: 10
        })
        if (code == 200) {
          this.total = body.total
          this.tableData = body.content
          this.tableHead = Object.keys(this.tableData[0])
        }
      } catch (error) {
        console.error('handleClick', error);
      }
    },
    /**
  * @description 结果数据表名
  * @param 
  */
    async getResultTableName () {
      try {
        let { code, body } = await getResultTableName({
          taskName: this.taskName
        })
        if (code == 200) {
          this.exampleData = body
          console.log('exampleData', this.exampleData);
        }
      } catch (error) {
        console.error('getResultData', error);
      }
    },
    /**
    * @description 下载数据
    * @param 
    */
    async downloadData () {
      try {
        let res = await downloadData({
          taskName: this.taskName,
          tableName: this.activeName,
        })
        let blob = new Blob([res]);
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = `${this.activeName}.xlsx`; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
        if (code == 200) {
          this.$message({
            message: '下载成功!',
            type: 'success'
          });
        }
      } catch (error) {
        console.error('downloadData', error);
      }
    },
    /**
     * @description  分页
     * @param 
     */
    pageChange (val) {
      this.pages = val
      this.handleClick()
    }
  },
};
</script>

<style lang="scss" scoped>
.task-result-page {
  padding: 30px 24px;
}

.active {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .add-taskp-btn {
    width: 100px;
    height: 36px;
    background: #507ce5;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
    padding: 0;
  }
}

.active-info {
  display: flex;
  align-items: center;

  .item-info {
    display: flex;
    align-items: center;
    margin-right: 20px;

    .lable {
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
    }

    .value {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
    }

    .template-name {
      min-width: 200px;
      padding: 0 10px;
      height: 36px;
      line-height: 36px;
      background: #ebf0ff;
      border-radius: 4px;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #507ce5;
    }
  }
}
</style>